import React, { useState, useEffect } from "react";
import "./shippingDelivery.scss";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "react-switch";
import { getMethod, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json"
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";
import Button from "../../../../components/button/button";
import { toast } from "react-toastify";

const ShippingDelivery = (props) => {
    const [switchStatus, setSwitchStatus] = useState(false);
    const [modalMe, setModalMe] = useState(false)
    const [storePickup, setStorePickup] = useState(false)
    const [deliverValue, setDeliverValue] = useState(false)
    const [canadaDelivery, setCanadaDeilvery] = useState(false)
    const [flatValue, setFlatValue] = useState(false)
    const [otherValue, setOtherValue] = useState(false)

    useEffect(() => {
        getSteps()
        getPerfenceDetai()
    }, [])

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    const getPerfenceDetai = async () => {
        await getMethod(urls.getPerfence).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setStorePickup(response?.data?.response?.instore_pickup)
                setDeliverValue(response?.data?.response?.local_delivery)
                setCanadaDeilvery(response?.data?.response?.standard_delivery)
                setFlatValue(response?.data?.response?.flat_rate)
                setOtherValue(response?.data?.response?.other_charges)
            }
        })
    }

    const handleStorePickupChange = (event) => {
        setStorePickup(event)

    }

    const handleDeliveryChange = (event) => {
        setDeliverValue(event)
    }

    const handleCanadaDeliverChange = (event) => {
        setCanadaDeilvery(event)
    }

    const handleFlatChange = (event) => {
        setFlatValue(event)
    }

    const handleOtherChange = (event) => {
        setOtherValue(event)
    }

    const handleSubmitShipping = async () => {
        let body = {
            "instore_pickup": storePickup,
            "local_delivery": deliverValue,
            "flat_rate": flatValue,
            "other_charges": otherValue,
            "standard_delivery": canadaDelivery
        }
        await putMethodWithToken(urls.putPrefences, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.response)
                getPerfenceDetai()
            }
        })
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <h1 className="col-12 heading-title">Shipping & Delivery</h1>
            </div>

            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="h4-label">Shipping Origin</div>
                            <div className="muiDescripationInfo mt-2">
                                This is the address from where your orders will be shipped. This
                                address will be used to calculato real-time shipping rates.
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <TextField
                                errorMessages={"Please Enter Address"}
                                validators={["minStringLength:1", "maxStringLength:30", "required"]}
                                onChange={(text) => console.log(text)}
                                name="address"
                                label="Address"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton className="edit">
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="h4-label">Pickup Destination</div>
                            <div className="muiDescripationInfo mt-2">
                                This is the address from where customers can pick up their orders.
                                Please provide instructions on how and when customers can pick up
                                their order.
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <TextField
                                errorMessages={"Please Enter Address"}
                                validators={["minStringLength:1", "maxStringLength:30", "required"]}
                                onChange={(text) => console.log(text)}
                                name="address"
                                label="Address"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton className="edit">
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mobileResponsive mt-3">
                <div className="col-12 h4-label">
                    Shipping & Delivery Methods
                </div>
                <div className="col-12 muiDescripationInfo mt-2">
                    Shopez has partnered with USPS and Canada Post to get you best rates for
                    the standard shipping in the USA and Canada. You can enable customers to
                    pickup their orders in store or get them delivered through your
                    preferred shipping carriers.
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3">
                <div className="col-12 h6-label">Type</div>
                <div className="col-12">
                    <div className="col-12 mt-2" style={{ background: "#F6F6F6", padding: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="h5-label">In-store pickup</div>
                                <p className="typeInfo">Enables customers to pick up from your store</p>
                            </div>
                            <td>
                                <Switch
                                    checked={storePickup}
                                    onColor="#00A739"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={(e) => handleStorePickupChange(e)}
                                />
                            </td>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="h5-label">Local delivery</div>
                                <p className="typeInfo">Select this option if you either deliver goods or use a local delivery service.(Zapper Express)</p>
                            </div>
                            <td>
                                <Switch
                                    checked={deliverValue}
                                    onColor="#00A739"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={(e) => handleDeliveryChange(e)}
                                />
                            </td>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="h5-label">Standard Shipping</div>
                                <p className="typeInfo">Select this option if you either deliver goods or use a canada post delivery service.</p>
                            </div>
                            <td>
                                <Switch
                                    checked={canadaDelivery}
                                    onColor="#00A739"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={(e) => handleCanadaDeliverChange(e)}
                                />
                            </td>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="h5-label">Flat Rate</div>
                                <p className="typeInfo">Let's you charge a fixed rate for shipping</p>
                            </div>
                            <td>
                                <Switch
                                    checked={flatValue}
                                    onColor="#00A739"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={(e) => handleFlatChange(e)}
                                    disabled={true}
                                />
                            </td>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="h5-label">Other Charges</div>
                                <p className="typeInfo">Enables you to collect tips and gratuities for local deliveries.</p>
                            </div>
                            <td>
                                <Switch
                                    checked={otherValue}
                                    onColor="#00A739"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={(e) => handleOtherChange(e)}
                                    disabled={true}
                                />
                            </td>
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end mt-5">
                    <Button onClick={() => handleSubmitShipping()} name="Save" className="btn btn-primary mb-5" />
                </div>
                <OnHoldModal isOnHold={modalMe} />
            </div>
        </div>
    );
};
export default ShippingDelivery;
